:root{
  --primary: #751396;
  --secondary: #fee800;
}
@font-face {
  font-family: 'IranSans';
  src: url(./IranSans.woff2);
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(./material-icons.woff2) format('woff2');
}
.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
body {
  margin: 0;
  font-family: 'IranSans';
  color: #495057 !important;
  overflow-x: hidden;
  background-color: #f3f4f6 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
.py-3{
  margin: 3px 0 !important;
}
.mt-20{
  margin-top: 20px !important;
}
.mt-15{
  margin-top: 15px !important;
}
.mt-10{
  margin-top: 10px !important;
}
.mb-10{
  margin-bottom: 10px !important;
}
.mb-0{
  margin-bottom: 0 !important;
}
.my-5{
  margin: 5px 0 !important;
}
.my-10{
  margin: 10px 0 !important;
}
.my-20{
  margin: 20px 0 !important;
}
.my-40{
  margin: 40px 0 !important;
}
.mb-40{
  margin-bottom: 40px!important;
}
.mx-15{
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.text-center{
  text-align: center;
}
.MuiPaper-elevation2{
  box-shadow: none !important;
}
a{
  text-decoration: none;
  color: inherit;
}
.absolute{
  position: absolute;
}
.inset-0{
  inset:0
}
.opacity-2{
  opacity: 0.2;
}
.opacity-4{
  opacity: 0.4;
}
.text-center{
  text-align: center;
}
.MuiPaper-elevation2{
  box-shadow: none !important;
}

.withCursor{
  cursor: pointer;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;

}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.img-responsive{
  display: inline-block;
  max-width: 100%;
}
.MuiDivider-light{
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'IranSans';
  font-size: 14px;
  padding: 15px;
  position: relative;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}
.RichEditor-editor img{
  max-width: 100%;
}
.editor-view{
  font-family: 'IranSans';
  font-size: 14px;
  margin:10px 0
}
.editor-view a{
  text-decoration: none;
}
.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'IranSans';
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
  display: inline;
}

.RichEditor-styleButton {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #328be0
}
.tox-notifications-container{
  display: none;
}
.rdw-link-decorator-wrapper{
  color:blue
}
.rdw-image-modal{
  right:5px
}
.rdw-dropdown-carettoopen{
  left:10px;
  right: unset !important;
}

.MuiSnackbarContent-message .MuiSvgIcon-root {
  margin-left: 10px;
}

span.MuiButtonBase-root.MuiIconButton-root{
  margin-left: 0 !important;
}

/*
#ProviderUserDetails tr.MuiTableRow-root[index="0"] span[title="ویرایش سریع"] .MuiButtonBase-root
,#ProviderUserDetails tr.MuiTableRow-root[index="0"] span[title="پاک کن"] .MuiButtonBase-root{
  display: none;
}
#ProviderUserDetails tr.MuiTableRow-root[index="0"]{
  background-color: rgba(70, 200, 30, 0.1);
}*/
.profile{
  width: 40px;
  border-radius: 50%;
  border: 1px solid #ffdd14;
}
.heading{
  font-weight: 700 !important;
  display: block;

}
.subheading{
  font-weight: 700 !important;
  margin-top: 0 !important;
    font-size: 15px !important;
}
.subheadingLight{
  margin-top: 0 !important;
    font-size: 15px !important;
}

.MuiListItemIcon-root{
  min-width: 50px !important;
}

.MuiPaper-elevation4{
  box-shadow: none !important;
}
.MuiBreadcrumbs-separator{
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.TitleContainer h2{
  font-size: 20px;
  text-align: right;
  padding: 12px 10px 10px;
}
.TitleContainer h2:after{
  width: unset;
  transform: unset;
  margin-right: unset;
}
.SubTitleContainer{
  background: #22b2b8;
  margin-top: -25px !important;
  border-radius: 3px;
  margin-bottom: 15px !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  padding-bottom: 5px !important;
}
.SubTitleContainer svg{
  color: #fff !important;
}
.SubTitleContainer h3{
  color: #fff !important;
}
.MuiLinearProgress-root{
  height: 5px !important;
  margin-bottom: 5px;
  border-radius: 5px;
}
.MuiStepLabel-root,.MuiStepLabel-root.Mui-disabled{
  cursor: pointer !important;
}
.MuiTypography-body1{
  font-size: 15px;
}
.MuiStepper-root{
  padding-top: 10px !important;
}
.MuiListItemText-root span.MuiTypography-body1{
  font-size: 14px !important;
}
.attributes-holder{
  margin-top:20px;
}
.attributes-holder .MuiExpansionPanel-root{
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid #22b2b8;
}
.autoCompleteHolder .MuiAutocomplete-endAdornment {
  top: calc(50% - 23px) !important;
}
.textCounter .MuiLinearProgress-barColorPrimary {
  background-color: #4caf50 ;
}
.textCounterError .MuiLinearProgress-barColorPrimary {
  background-color: #d12c47;
}
.textCounterError.MuiLinearProgress-root,.textCounter.MuiLinearProgress-root{
  border-radius: 20px !important;
  height: 3px !important;
}
.iconButtonEdit{
  position: absolute !important;
  left: 12px !important;
  top: 27px !important;
}

.MuiCollapse-wrapper .MuiListItemIcon-root{
  min-width: 35px !important;
}

.productFeature {
  background-color: rgb(156, 39, 176);
  border-radius: 4px;
  padding: 5px 15px;
  display: inline-block;
  color: #fff;
}
.VerifyEmailDrawer .MuiPaper-root{
  background-color: rgba(230, 87, 87, 0.90) !important;
  color: #ffffff !important;
  overflow: hidden;
  z-index: 1201;

}

.VerifyEmailDrawer .VerifyEmailButton {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #ffffff !important;
}
@media (max-width: 768px) {
  .VerifyEmailDrawer .VerifyEmailButton {
    position: relative;
    top: -20px;
    color: #ffffff !important;
  }
}

.
{
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: #1f1f1f7a;
}
.inActiveCard{
  border:2px solid transparent;
}
.activeCard{
  border:2px solid #ffb63e;
}
.doItLaterButton{
  position: absolute !important;
  left: 10px;
}
.noneListStyle{
  list-style-type: none;
}
.FieldIcon{
  position: absolute !important;
  top: 6px !important;
  right: 5px !important;
}
.FieldIconLeft{
  position: absolute !important;
  top: 6px !important;
  left: 5px !important;
}
.MuiTypography-root.MuiTypography-h6{
  font-size:16px !important;
  font-weight: 700;
}
.MuiFormLabel-root{
  font-size:.9rem !important;
}
.avatarBigCenter{
  width: 200px !important;
  height: 200px !important;
  margin-right: 50%;
  transform: translateX(50%);
  margin-top:20px;
  margin-bottom:20px;
}
.categoryHolderPro{
  height: 300px;
  overflow-y: auto;
}
.categoryParent span{
  font-weight: 700 !important;
  color : #328be0;
}

.GoogleSimulator{
  direction: ltr;
}
.GoogleSimulator .url{
  font-size: 12px;
  font-weight: 700;
}
.GoogleSimulator .title{
  font-size: 20px;
  color: #1a0dab
}
.wordCounter-Editor{
  position: absolute;
  bottom: 0;
  left: 4px;
  background: #d7d7d7;
  padding: 5px;
  z-index:1;
  border-radius:4px 4px 0 0
}
.hoverLinkMenu{
  cursor: pointer;
  color: #3b64ef;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s all;
}

.sketch-picker > div,.sketch-picker  div.flexbox-fix{
  display: none !important;
}
.sketch-picker  div.flexbox-fix:last-child{
  display: flex !important;
}
.sketch-picker{
  padding: 0 10px 0 !important;
}

.ColorPicker_holder > div {
  display: inline;
}
.ColorPicker_holder{
  margin: 5px  15px;
  display: inline-block;
  position: relative;
}

.thumbnailCloser{
  position: absolute !important;
  top:0 !important;
  right: 0 !important;
}
.field-sticky{
  position: sticky;
  top: 60px;
  background-color: #ffffff;
  z-index: 900;
}
.MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 4px 0 !important;
}
.MuiExpansionPanelSummary-content {
  margin: 2px 0 !important;

}
.link {
  color: #328be0;
  text-decoration: none;
}
.jsonEditor div{
  margin:5px !important;
  font-family: "IranSans";
  color: #5377c0 !important;
  min-width: 150px;
}
.jsonEditor input{
  font-family: "IranSans";
  min-width: 300px;
  padding: 7px !important;
}
.jsonEditor span[title="remove item"]{
  display: none;
}
.gridLink{
  cursor: pointer;
}
.gridLink:hover button{
  transform: scale(1.1);
  background: rgba(0, 0, 0, 0.8);

}
.MuiTypography-h5 {
  font-size: 1.3rem !important;
}
.editor-control{
  background: #d7d7d7;
  border-radius:4px;
}
.badgeForMenu .MuiBadge-anchorOriginTopRightRectangle{
  top:11px;
  left:-20px;
}
.announcementHolder{
  position: relative;
  margin-top:5px;
  margin-bottom: 10px;
  text-align: right;
}
.announcementHolder .MuiCardContent-root:last-child {
  padding-bottom:10px;
}
.announcementHolder .time-shower{
  position: absolute;
  left: 5px;
  top: 105px;
  background: #fff;
  border-radius: 20px;
  font-size: 13px;
  padding: 5px 10px;
}
.announcementHolder .new-item{
  position: absolute;
  right: 5px;
  top: 105px;
  background: #76d363;
  border-radius: 20px;
  font-size: 13px;
  padding: 5px 10px;
  color:#fff !important;
}
.onClickedField{
  position: absolute;
}
.largeFieldHolder{
  position: absolute;
  top:0;
  right:0;
  background: #ffffff;
  z-index: 999;
}
.largeField{
  width:300px;
}
.app-bar{

}
.draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq {
  margin-right: 8px;
}
.MuiButton-contained,.MuiButton-root{
  box-shadow: none !important;
}

.insta-field input{
  direction: ltr;
}
.notifications-holder {
    padding:10px;
    height:88%;
    overflow:auto
}
.notifications-holder .MuiAlert-message {
     width: 100%;
 }

.MuiTableRow-root[level="1"]{
  background-color: #f5f5f5;
}
.MuiTableRow-root[level="2"]{
  background-color: #e7e7e7;
}
.MuiTableRow-root[level="3"]{
  background-color: #e1dede;
}
.MuiTableRow-root[level="1"] span.MuiButtonBase-root.MuiIconButton-root {
  margin-right: 20px;
  margin-left: 0 !important;
}
.MuiTableRow-root[level="2"] span.MuiButtonBase-root.MuiIconButton-root {
  margin-right: 40px;
  margin-left: 0 !important;
}
.MuiTableRow-root[level="3"] span.MuiButtonBase-root.MuiIconButton-root {
  margin-right: 60px;
  margin-left: 0 !important;
}
.MuiTableCell-root{
    padding:9px !important;
}
.scrollbar-container{
  overflow-x: hidden !important;
  height: 85% !important;
  overflow-y: auto;
  padding-bottom: 20px;
}
.liveUserNumber{
  margin: 30px;
  font-size: 60px;
  font-weight: 700;
  display: block;
  text-align: center;
  cursor: pointer;
}
.liveUserNumber span{
  font-size: 20px;
  font-weight: 500;
}
.imageViewer{
  display: inline-block;
  max-width: 100%;
}
.float-left{
  float: left;
}
.float-right{
  float: right;
}
.sticky-grid{
    top:0;
    position: sticky;
}
.thin-table .MuiTableRow-root{
  height:0 !important;
}
.thin-table .MuiToolbar-root{
  display: none;
}
.navbarNav .MuiIcon-root{
  color: #808183;
  font-size: 1.3rem;
  margin-left: 4px !important;
}
.instagram-btn{
  background: linear-gradient(
          45deg
          , #405de6,#5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
}
.insta-modal .MuiDialog-paper{
  background-color: #fbfbfb !important;
}
.error-text{
  font-size: 0.75rem !important;
  margin: 5px 20px !important;
  color:#f44336 !important;
}
.privacy_policy{
  font-size: 12px !important;
  margin-bottom: 10px !important;
  margin-top:10px!important
 }
.privacy_policy a{
  color:var(--primary)
}
.date_in_table{
  font-size: 12px !important;
}
.melli-card{
  max-width: 150px;
  border-radius: 4px;
}
.user_card{
  text-align: center;
  border: 1px solid #ddd;
  padding: 10px 20px;
  border-radius:4px
}
.user_card_content{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
@media (max-width:768px){
  .user_card_content{
    display: flex;
    flex-direction: column;
  }
  .MuiDialogContent-root{
    padding:2px 6px !important;
  }
  .MuiTablePagination-select{
    display: none !important;
  }
}
.user_card_text{
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.user_card_info{
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.make_center_in_card{
  position: absolute;
  top: 50%;
  width: 100%;
  right: 0;
  transform: translateY(-50%);
}
.modal-close-btn {
  position: absolute!important;
  left: 10px;
  top: 10px;
}
.only-two-line{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.only-one-line{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.card_resume_action{
  position: absolute;
  left:0;
  bottom: 0;
  justify-content: flex-end;
}
.chrome-picker {
  box-shadow: none !important;
  border:1px solid #ddd;
}
.chrome-picker div svg {
  margin-right: 10px;
  margin-top: -10px;
}
.colored_badge{
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  vertical-align: middle;
  margin-left: 5px;
}
.value_item{
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  padding: 5px;
  border-radius: 4px;
  margin-left: 10px !important;
  margin-bottom: 10px !important;
  min-width: 170px;
}
.makeSinglePricingHolder{
  background-color: #fdfdfd;
  border-radius: 8px;
  padding:0 20px;
    padding-bottom: 20px;
  margin-bottom: 30px;
  margin-top: 10px;
    box-shadow:2px 1px 6px rgba(51,51,5,0.1)
}
.modifySinglePricingHolder{
    box-shadow:none;
}
.MuiTableCell-head div{
  white-space: nowrap;
}
.fileChooser-image{
  width:100%;
  border-radius:5px;
}
.no_wishlist_container{
  min-height: 300px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.no_wishlist_container p{
  font-size: 14px;
  font-weight: 700;
}
.wishlist_title{
  font-size:13px !important;
  font-weight: 700 !important;
}
.litle_description_text{
  font-size:11px !important;
  color:#999
}
.expired_at .DatePicker{
  width:100% !important;
}
.order-items-holder{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.order-items-holder p{
  margin-left: 20px;
  color: #999;
  margin-bottom: 10px;
}
.order-items-holder p span.result{
  color:#000000;
  padding: 0 5px;
}
#dark_root .order-items-holder p span.result{
  color:#ffffff;
}
.order-items-holder p svg{
  font-size: 8px;
  color: #ddd;
}
.MuiDivider-root {
  background-color: rgba(0,0,0,0.05) !important;
}
.single_order_items{
  padding: 15px 15px !important;
}
.single_order_items.buttons_holder{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.single_order_items svg{
  font-size: 18px !important;
  color: #999;

}
.single_order_items .MuiIconButton-root{
  margin-left: 10px;
}
.single_order_items p{
  font-size: 12px !important;

}
.single-order-items-img{
  max-width: 55px;
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
}
.order-items-action{
  background-color: rgb(243, 250, 255);
  border-radius: 4px;
  padding: 20px !important;
}
.guidline-holder{
  border:1px solid #ddd;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.guidline-single{
  position: relative;
  text-align: center;
  border: 1px solid #ddd;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 6px;
  padding: 20px;
}
.disabled-appCard{
  filter: grayscale(100%);

}
.disabled-appCard img{
  filter: grayscale(100%);
}
.disabled-appCard h2,.disabled-appCard p{
  color:rgba(0, 0, 0, 0.5) !important
}
.simple-error-icon{
  color:#f44336;
  font-size: 1rem !important;
}
.simple-success-icon{
  color: #00d35b;
  font-size: 1rem !important;
}
.simple-error-text{
  color:#333;
  font-size: 11px !important;
  display: block;
  text-align: center;
}
.guidline-arrow{
  position: absolute;
  left:5px;
  top:50%;
  transform: translateY(-50%);
}
.simple-error-text a{
  border: 1px solid #ddd;
  padding: 3px;
  border-radius: 5px;
  white-space: nowrap;
  display: inline-block;
  margin-left: 5px;
  font-size: 10px;
}
.thumbnail_description{
  font-size: 12px !important;
  text-align: center;
  margin-top: 5px !important;
}
.MuiTable-root thead tr{
  white-space: nowrap;
}
.date-holder{
  display: flex;
  justify-content: space-between;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.header-part-chooser{
  cursor: pointer;
  margin-top: 20px;
  border: 2px solid transparent;
  padding:10px;
  border-radius:10px;
}
.header-part-chooser img{
  max-width: 100%;
  filter: grayscale(100%);
}
.header-part-chooser.active{
  border: 2px solid #00d35b;
}
.header-part-chooser.active img{
  filter: grayscale(0);
}
.draggable_list_item{
  margin-bottom: 15px;
  background: #f1f1f1;
  border-radius:8px;
  padding-left:16px !important;
}
textarea.npm__react-simple-code-editor__textarea{
    outline:none !important;
}
.color-picker-container{
  border-radius: 10px !important;
  background-color: #0e1929 !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  padding: 10px 25px !important;
  margin-left:20px !important;
}
.color-picker-container p{
  color: #ffffff;
}
.color-picker-badge{
  display: inline-block;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  vertical-align: middle;
  margin-left:10px;
  margin-right:10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.4)
}
.slide_show_item{
  padding: 40px;
  padding-bottom: 60px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
    background-position: center;
  min-height:160px

}
.slide_show_item h3{
    background: rgba(255, 255, 255,0.6);
    backdrop-filter: blur(3px);
    border-radius: 12px;
    color:#1e1e1e;
    padding:5px;
    font-size: 13px;
}
.slide_show_item button{
  color: #ffffff !important;
  border-color: #ffffff !important;
}
.banner_item{
  background-color: #eeeeee;
}
.slide_show_item img{
  height: 80px;
}
.banner_item img{
  max-width: 100%;
  height: unset;
}
.slide_show_item .controller{
  position: absolute;
  bottom: 0;
  right:0;
  width:100%;
  background-color: #878787;
  padding: 5px;
  border-radius:0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.active-badge{
  background-color: rgba(62, 62, 64, 0.3);
  position: absolute;
  top: 10px;
  right:10px;
  padding: 5px 10px;
  border-radius: 10px;
  color: #00d34c;
  font-weight: 700;
  font-size: 12px;
  backdrop-filter: blur(5px);
}

.pac-target-input{
  width: 100%;
  line-height: 25px;
  padding: 5px 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ddd

}
.MuiButton-root{
  text-transform: unset !important;
}

.date-holder>span{
    font-size:13px;
}
.date-holder strong{
    font-size:12px;
}

.expired{
  color:#dc3545
}
.drag-container{
  padding-top:0
}
.drag-container .MuiListItem-root{
  top:auto !important;
  left: auto !important;
}
.MuiAlert-standardInfo {
  color: rgb(255 255 255) !important;
  background-color: #848a9f !important
}
.MuiAlert-standardInfo .MuiAlert-icon {
  color: #ffef06 !important;
}
.inline-badge{
  background: #ffef06 !important;
  color: #000;
  border-radius: 100%;
  padding: 3px;
  margin-left: 5px;
  display: inline-block;
  height: 18px;
  width: 18px;
  font-size: 11px;
  line-height: 15px;
}
.sub-btn{
  font-size: 11px;
  margin-right: 5px;
}
.steps_holder{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
}
.steps_holder .step{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;
  filter:grayscale(1);
}
.steps_holder .step img{
  width: 45px;
  max-width: 100%;
  filter:blur(1px);
  margin-bottom: 6px;
}
.steps_holder .separator{
  margin-top: 15px;
  filter:grayscale(1) blur(1px);
}
.steps_holder .separator svg{
  font-size: 2rem;
}
.steps_holder .step.active , .steps_holder .separator.active,.steps_holder .step.active img{
  filter:unset;

}
.waiting-container{
  text-align: center;
  padding: 50px;
}
.waiting-container img{
  max-width: 100px;
  margin: 30px 0;
}
.faults{
  text-align: center;
  border: 1px solid #7f1313;
  white-space: pre-wrap;
  padding: 20px;
  margin: 20px 0 !important;
  border-radius: 6px;
  font-size: 13px !important;
  line-height: 26px !important;
  color: #7f1313;
  background: rgba(255,201,201,.9);

}
.faults.small{
  margin: 0 !important;
  padding: 12px;
}
.my-plan-container{
  text-align: center;
}
.my-plan-container img{
  width: 100px;
}
#rtl_root .order-items-holder p{
  direction: rtl;
}
#ltr_root .order-items-holder p{
  direction: ltr;
}

@media (max-width: 1279.95px){
  .MuiTableCell-body .MuiButton-root {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }
}
.reserve-radio{
  margin-top: 15px !important;
  text-align: left;
}
.reserve-radio .MuiTypography-root{
  font-size: 11px !important;
}
.reserve-radio .MuiFormLabel-root{
  font-size: 11px !important;
  margin-bottom: 10px;
}